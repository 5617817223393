import { type RibbonIconColor } from 'owa-command-ribbon';
import type { RibbonId } from 'owa-ribbon-ids';
import { type HelpRibbonControlId } from 'owa-ribbon-ids/lib/helpControlId';
import { type MailRibbonGroupId, type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';

export const MailIconColorMap: Map<RibbonId, RibbonIconColor> = new Map<RibbonId, RibbonIconColor>([
    [505, 3],
    [616, 4],
    [507, 0],
    [658, 0],
    [612, 0],
    [510, 3],
    [518, 4],
    [625, 3],
    [525, 2],
    [527, 0],
    [528, 2],
    [113, 4],
    [128, 0],
    [145, 4],
    [531, 0],
    [532, 4],
    [535, 0],
    [652, 4],
    [537, 4],
    [538, 3],
    [540, 4],
    [545, 4],
    [546, 2],
    [547, 2],
    [548, 4],
    [550, 2],
    [553, 5],
    [696, 5],
    [698, 5],
    [699, 5],
    [700, 5],
    [657, 0],
    [678, 5],
    [555, 4],
    [680, 4],
    [651, 4],
    [556, 5],
    [679, 5],
    [557, 5],
    [681, 5],
    [570, 4],
    [581, 4],
    [643, 4],

    /**************** format controls ****************/
    [6029, 2],
    [6011, 2],
    [6012, 2],

    /**************** action controls ****************/
    [638, 4],
    [623, 4],
    [506, 4],
    [7004, 3],
    [7029, 4],
    [639, 2],
    [7012, 2],
    [7011, 1],
    [7000, 4],
    [529, 1],
    [7005, 0],
    [7006, 4],
    [560, 5],
    [7035, 5],
    [7041, 5],
    [7040, 4],
    [564, 4],

    /**************** help tab controls ****************/
    [11201, 1],
    [11210, 4],
    [11202, 4],
    [11203, 3],
    [11204, 5],
]);
